import { Component } from '@angular/core';
import { SpokeRequestCompleteDto } from '../../api/spoke-request-complete.model';
import { SpokeRequestsService } from '../../api/services/spoke-requests.service';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { tap } from 'rxjs';

@Component({
  selector: 'app-spoke-requests-data',
  templateUrl: './spoke-requests-data.component.html'
})
export class SpokeRequestsDataComponent {

  formReady: boolean = false;

  spokeRequestMappingId?: string = undefined;
  spokeRequest?: SpokeRequestCompleteDto = undefined;

  constructor(
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notify: NotificationService,
    private spokeRequestsService: SpokeRequestsService,
  ) {
    this.spokeRequestMappingId = this.config.data.mappingId;
  }

  async ngOnInit() {
    await this.refreshSpokeRequestDetails();
  }

  async refreshSpokeRequestDetails() {
    if (this.spokeRequestMappingId)
    this.spokeRequestsService.spokeRequestsGetById(this.spokeRequestMappingId)
      .pipe(tap(_ => this.formReady = true))
      .subscribe((response: SpokeRequestCompleteDto) => this.spokeRequest = response);
  }

}
