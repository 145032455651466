import { PrimaryHubDataDto } from "./partials/primary-hub-data.model";

export class PrimaryHubDto {

    hubId?: string = '';
    hubDisplayName?: string = '';
    owner?: string = '';
    projectId?: string = '';
    projectName?: string = '';
    primaryHubData?: PrimaryHubDataDto = undefined;

    constructor(partial?: Partial<PrimaryHubDto>) {
        Object.assign(this, partial);
    }
}
