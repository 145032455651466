import { Component, OnInit } from '@angular/core';
import { PrimaryHubDto } from '../../api/primary-hub.model';
import { PrimaryHubService } from '../../api/services/primary-hub.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjectSpokesPrimaryHubDataComponent } from '../project-spokes-primary-hub-data/project-spokes-primary-hub-data.component';
import { LayoutService } from '../../../../../shared/services/layout.service';
import { ProjectSpokesPrimaryHubCreateComponent } from '../project-spokes-primary-hub-create/project-spokes-primary-hub-create.component';

@Component({
  selector: 'app-project-spokes-primary-hubs-list',
  templateUrl: './project-spokes-primary-hubs-list.component.html',
  providers: [ DialogService ],
  styles: ['']
})
export class ProjectSpokesPrimaryHubsComponentList implements OnInit {

  filters: PrimaryHubsFilter;
  filterTenants: string[] = [];
  filterProjects: string[] = [];
  filterOwners: string[] = [];
  filterEnvironments: string[] = [];

  primaryHubs: PrimaryHubDto[] = [];
  primaryHubsFiltered: PrimaryHubDto[] = [];

  hubDetailsDialog?: DynamicDialogRef;

  constructor(
    private primaryHubService: PrimaryHubService,
    private dialogService: DialogService,
    private layout: LayoutService,
  ) {
    this.filters = new PrimaryHubsFilter();
   }

  async ngOnInit() {
    await this.refreshList();
  }

  async refreshList() {
    this.layout.startLoading();
    this.primaryHubService.primaryHubsGet()
      .subscribe({
        next: (response: PrimaryHubDto[]) => {
          this.primaryHubs = response;
          this.refreshFilterLists(response);
          this.filterList();
          this.layout.stopLoading();
        },
        error: (error) => {
          this.layout.stopLoading();
        }
      });
  }

  refreshFilterLists(tableData: PrimaryHubDto[]) {
    this.filterTenants = [...new Set(tableData.map(q => q.primaryHubData?.tenantName).filter((q): q is string => q !== undefined && q !== null))];
    this.filterProjects = [...new Set(tableData.map(q => q.projectName).filter((q): q is string => q !== undefined && q !== null))];
    this.filterOwners = [...new Set(tableData.map(q => q.owner).filter((q): q is string => q !== undefined && q !== null))];
    this.filterEnvironments = [...new Set(tableData.map(q => q.primaryHubData?.environment).filter((q): q is string => q !== undefined && q !== null))];
  }

  filterList() {
    this.primaryHubsFiltered = this.primaryHubs.filter(q => {
      return (!this.filters.tenant || q.primaryHubData?.tenantName === this.filters.tenant) &&
              (!this.filters.project || q.projectName === this.filters.project) &&
              (!this.filters.owner || q.owner === this.filters.owner) &&
              (!this.filters.environment || q.primaryHubData?.environment === this.filters.environment);
    });
  }

  filtersClear() {
    this.filters.project = undefined;
    this.filters.tenant = undefined;
    this.filters.environment = undefined;
    this.filters.owner = undefined;

    this.filterList();
  }

  openHubDetailsDialog(hub?: PrimaryHubDto) {
    this.hubDetailsDialog = this.dialogService
      .open(ProjectSpokesPrimaryHubDataComponent, {
        data: { hub: hub },
        header: 'Primary Hub - Hub Data',
        width: '800px'
      });

  //dialog is read only we don't need to refresh the list
  //  this.hubDetailsDialog.onClose
  //    .subscribe(() => {
  //        this.refreshList();
  //    });
  }

  openCreatePrimaryHubDialog() {
    this.hubDetailsDialog = this.dialogService
      .open(ProjectSpokesPrimaryHubCreateComponent, {
        header: 'Primary Hub - Create New',
        width: '800px'
      });

    this.hubDetailsDialog.onClose
      .subscribe(() => {
          this.refreshList();
      });
  }
}

class PrimaryHubsFilter {
  project?: string;
  tenant?: string;
  environment?: string;
  owner?: string;
}
