import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationRule } from '../../../../../shared/models/validation-rule';
import { lastValueFrom, map, Observable, of, switchMap } from 'rxjs';

import { SpokeRequestsService } from '../../api/services/spoke-requests.service';
import { SpokeRequestInitDto } from '../../api/spoke-request-init.model';
import { SpokeRequestNetworkDataInitExcerptDto } from '../../api/partials/spoke-request-network-data-init-excerpt.model';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { ConfigurationService } from '../../../shared/api/configuration.service';
import { ProjectProfileService } from '../../../projects/api/project-profile.service';
import { UserDetailsService } from '../../../shared/api/user-details.service';
import { formAddValidators } from '../../../../../shared/utils';
import { UserRoles } from '../../../shared/api/user-details.model';

@Component({
  selector: 'app-spoke-request-create',
  templateUrl: './spoke-request-create.component.html'
})
export class SpokeRequestCreateComponent {

  formBlocked: boolean = false;

  spokeMetadataForm?: FormGroup;
  spokeDataExcerptForm?: FormGroup;

  spokeMetadataValidators: ValidationRule[] = [];
  spokeDataValidators: ValidationRule[] = [];

  deploymentStageOptions?: Observable<string[]>;
  azureRegionOptions?: Observable<string[]>;
  spokeTopologyOptions:any[] = [];

  projectOptions?: Observable<string[]>;

  constructor(
    private userService: UserDetailsService,
    private spokeRequestsService: SpokeRequestsService,
    private projectsService: ProjectProfileService,
    private dialogRef: DynamicDialogRef,
    private notify: NotificationService,
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
  ) { }

  async ngOnInit() {
    const newSpokeRequest = new SpokeRequestInitDto();
    const spokeDataExcerpt = new SpokeRequestNetworkDataInitExcerptDto();

    this.spokeMetadataValidators = await lastValueFrom(this.spokeRequestsService.getValidators('GpSpokeCreateRequest'));
    this.spokeMetadataForm = formAddValidators(this.formBuilder.group(newSpokeRequest), this.spokeMetadataValidators);

    this.spokeDataValidators = await lastValueFrom(this.spokeRequestsService.getValidators('GpSpokeCreateRequest', 'SpokeData'));
    this.spokeDataExcerptForm = formAddValidators(this.formBuilder.group(spokeDataExcerpt), this.spokeDataValidators );
    this.spokeDataExcerptForm.controls['namingConvention'].setValue('spoke');

    this.deploymentStageOptions = this.configurationService.deploymentStages();
    this.azureRegionOptions = this.configurationService.azureRegionsForSpokes();
    this.spokeTopologyOptions = this.configurationService.spokeTopologies();

    // TODO SnP use 'Lazy Virtual Scroll' and search in dropdown to support large amount of projects
    this.projectOptions = this.userService.userMeGet()
      .pipe(
        switchMap(user => {
          if (user.roles.filter(x => x === UserRoles.Administrator || x === UserRoles.Operations).length > 0) { // isAdmin
            return this.projectsService.projectProfiles()
            .pipe(
              map(profiles => profiles.map(profile => profile.projectId))
            );
          }
          else {
            return of(Object.keys(user.projects));
          }
        })
      );
  }

  async onNamingConventionChange() {
    const namingConventionCurrValue = this.spokeDataExcerptForm?.get('namingConvention')?.value;
    if (!namingConventionCurrValue || namingConventionCurrValue === '') {
      this.spokeDataExcerptForm?.controls['namingConvention'].setValue('spoke');
    }
  }

  async onCancelClick() {
    this.dialogRef.close();
  }

  async onSaveClick() {
    await this.createNewSpoke();
  }

  async createNewSpoke() {
    if (this.spokeMetadataForm?.valid) {
      this.formBlocked = true;

      const spokeRequest = <SpokeRequestInitDto> this.spokeMetadataForm?.value;
      spokeRequest.spokeData = this.spokeDataExcerptForm?.value;

      this.spokeRequestsService.SpokeCreateRequest(spokeRequest)
      .subscribe({
        next: result => {
          if (result) {
            this.notify.success(`Created new Spoke Request with Spoke ID: '${result.spokeId}'`)
            this.dialogRef.close(result);
          } else {
            this.notify.error('Failed', result);
          }
          this.formBlocked = false;
        },
        error: e => this.formBlocked = false
      });
    }
  }

  getErrorsFromControl(form: FormGroup, controlName: string) : string[] {
    const errors = form?.controls[controlName]?.errors;
    return errors ? Object.values(errors) : [];
  }
}
