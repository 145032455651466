export class SecondaryHubSubcategoryDetailsSimpleDto {

  subcategoryId: string = '';
  subcategoryDescription: string = '';

  constructor(partial?: Partial<SecondaryHubSubcategoryDetailsSimpleDto>) {
    Object.assign(this, partial);
  }

}
