import { Component, OnInit } from '@angular/core';
import { ProjectSpokeCompleteDto } from '../../api/project-spoke-complete.model';
import { ProjectSpokesService } from '../../api/services/project-spokes.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { tap } from 'rxjs';

@Component({
  selector: 'app-project-spokes-data-listing',
  templateUrl: './project-spokes-data-listing.component.html'
})
export class ProjectSpokesDataListingComponent {
  formReady: boolean = false;

  projectSpoke? : ProjectSpokeCompleteDto;

  projectId? : string;
  spokeId?: string;

  constructor(
    private projectSpokesService : ProjectSpokesService,
    private config: DynamicDialogConfig
  ) {
    this.projectId = this.config.data.projectId;
    this.spokeId = this.config.data.spokeId;
  }

  async ngOnInit() {
    this.refreshProjectSpokeDetails();
  }

  async refreshProjectSpokeDetails() {
    if (this.projectId && this.spokeId)
      this.projectSpokesService.projectSpokeGetById(this.projectId, this.spokeId)
        .pipe(tap(_ => this.formReady = true))
        .subscribe((response: ProjectSpokeCompleteDto) => this.projectSpoke = response);
  }
}
