import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { SecondaryHubCompleteDto } from '../../api/secondary-hub-complete.model';
import { SecondaryHubsService } from '../../api/services/secondary-hub.service';
import { tap } from 'rxjs';

@Component({
  selector: 'app-project-spokes-secondary-hub-data',
  templateUrl: './project-spokes-secondary-hub-data.component.html'
})
export class ProjectSpokesSecondaryHubDataComponent implements OnInit {

  formReady: boolean = false;

  secondaryHubCategoryId?: string = undefined;
  secondaryHubSubcategoryId?: string = undefined;
  secondaryHubId?: string = undefined;

  shub?: SecondaryHubCompleteDto = undefined;

  constructor(
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notify: NotificationService,
    private secondaryHubsService: SecondaryHubsService,
  ) {
    this.secondaryHubCategoryId = this.config.data.categoryId;
    this.secondaryHubSubcategoryId = this.config.data.subcategoryId;
    this.secondaryHubId = this.config.data.secondaryHubId;
  }

  async ngOnInit() {
    this.refreshSecondaryHubDetails();
  }

  async refreshSecondaryHubDetails() {
    if (this.secondaryHubCategoryId && this.secondaryHubSubcategoryId)
    this.secondaryHubsService.secondaryHubGetByCategory(this.secondaryHubCategoryId, this.secondaryHubSubcategoryId)
      .pipe(tap(_ => this.formReady = true))
      .subscribe((response: SecondaryHubCompleteDto) => this.shub = response);
  }

}
