<div class="flex mt-2 mb-2 justify-content-between">
  <span class="text-4xl">Primary Hubs</span>

  <p-button label="Create" icon="pi pi-plus" styleClass="p-button-link" (click)="openCreatePrimaryHubDialog()"></p-button>
</div>

<div class="flex mt-2 mb-2 justify-content-around">
  <div class="flex-1 mr-2">
    <p-dropdown [options]="filterProjects"
      [(ngModel)]="filters.project"
      [editable]="false"
      placeholder="Select the Project..."
      [showClear]="true"
      (onChange)="refreshList()"
      appendTo="body"
      styleClass="w-full"
      inputStyleClass="w-full">
  </p-dropdown>
</div>
<div class="flex-1 mr-2">
  <p-dropdown [options]="filterTenants"
    [(ngModel)]="filters.tenant"
    [editable]="false"
    placeholder="Select a Tenant..."
    [showClear]="true"
    (onChange)="refreshList()"
    appendTo="body"
    styleClass="w-full"
    inputStyleClass="w-full">
</p-dropdown>
</div>
<div class="flex-1 mr-2">
  <p-dropdown [options]="filterEnvironments"
    [(ngModel)]="filters.environment"
    [editable]="false"
    placeholder="Select an Environment..."
    [showClear]="true"
    (onChange)="refreshList()"
    appendTo="body"
    styleClass="w-full"
    inputStyleClass="w-full">
</p-dropdown>
</div>
<div class="flex-1 mr-2">
  <p-dropdown [options]="filterOwners"
    [(ngModel)]="filters.owner"
    [editable]="false"
    placeholder="Select an Owner..."
    [showClear]="true"
    (onChange)="refreshList()"
    appendTo="body"
    styleClass="w-full"
    inputStyleClass="w-full">
</p-dropdown>
</div>
<div class="">
  <i pButton [rounded]="true" [text]="true" size="small" severity="secondary" icon="pi pi-times" (click)="filtersClear();"></i>
</div>
</div>

<p-table
  [value]="primaryHubsFiltered"
  styleClass="p-datatable-sm w-full"
  [rowHover]="true"
  sortField="name">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="hubId">Primary Hub Id</th>
      <th pSortableColumn="hubDisplayName">Name</th>
      <th pSortableColumn="owner">Owner</th>
      <th pSortableColumn="projectName">Project Name</th>
      <th pSortableColumn="tenantName">Tenant Name</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-i="rowIndex">
    <tr (dblclick)="openHubDetailsDialog(item)" class="cursor-pointer">
      <td>{{item.hubId ?? 'ERROR' }}</td>
      <td>{{item.hubDisplayName ?? 'n/a' }}</td>
      <td>{{item.owner ?? 'n/a' }}</td>
      <td>{{item.projectName ?? 'n/a' }}</td>
      <td>{{item.primaryHubData.tenantName ?? 'n/a' }}</td>
      <td class="text-right">
        <p-button
          icon="pi pi-info-circle"
          styleClass="p-button-link"
          (click)="openHubDetailsDialog(item)
          "pTooltip="Show Details"
          tooltipPosition="left"></p-button>
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="flex align-items-center justify-content-center" *ngIf="primaryHubs?.length === 0">
  <p class="py-8">The list is empty</p>
</div>
