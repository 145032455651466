import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectSpokeSimpleDto } from '../project-spoke-simple.model';
import { BaseApiService } from '../../../../../shared/services/_base-api.service';
import { NpsStore } from '../../../../services/nps.store';
import { ProjectSpokeCompleteDto } from '../project-spoke-complete.model';
import { IngressDownloadOptions } from '../../components/project-spokes-data-download/ingress-download-options.model';
import { EgressDownloadOptions } from '../../components/project-spokes-data-download/egress-download-options.model';
import { ValidationRule } from '../../../../../shared/models/validation-rule';
import { NpsConfigurationApiUrl } from '../../../shared/api/configuration.service';

export const NpsProjectSpokesUrl = {
  ProjectSpokesGetList: (projectId: string) => `/api/projects/profiles/${projectId}/spokes`,
  ProjectSpokeGetById: (projectId: string, spokeId: string) => `/api/projects/profiles/${projectId}/spokes/${spokeId}`,
  GpProjectSpokeLogsIngressGet: (projectId: string, spokeId: string) => `/api/projects/profiles/${projectId}/spokes/${spokeId}/logs/ingress`,
  GpProjectSpokeLogsEgressGet: (projectId: string, spokeId: string) => `/api/projects/profiles/${projectId}/spokes/${spokeId}/logs/egress`,
}

@Injectable({
  providedIn: 'root'
})
export class ProjectSpokesService extends BaseApiService {

  constructor(store: NpsStore) {
    super(store.apiUrl);
  }

  projectSpokesGetListByProject(projectId: string) : Observable<ProjectSpokeSimpleDto[]> {
    return super.get<ProjectSpokeSimpleDto[]>(NpsProjectSpokesUrl.ProjectSpokesGetList(projectId));
  }

  projectSpokeGetById(projectId: string, spokeId: string) : Observable<ProjectSpokeCompleteDto> {
    return super.get<ProjectSpokeCompleteDto>(NpsProjectSpokesUrl.ProjectSpokeGetById(projectId, spokeId));
  }

  spokeMonitoringLogsDownloadIngress(projectId: string, spokeId: string, options: IngressDownloadOptions) : Observable<Blob> {
    return super.postWithBlobResponse(NpsProjectSpokesUrl.GpProjectSpokeLogsIngressGet(projectId, spokeId), options);
  }

  spokeMonitoringLogsDownloadEgress(projectId: string, spokeId: string, options: EgressDownloadOptions) : Observable<any> {
    return super.postWithBlobResponse(NpsProjectSpokesUrl.GpProjectSpokeLogsEgressGet(projectId, spokeId), options);
  }

  getValidators(method: keyof typeof NpsProjectSpokesUrl, part: string = '') {
    return super.get<ValidationRule[]>(NpsConfigurationApiUrl.ConfigurationValidators(), { method, part });
  }

}
