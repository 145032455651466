import { NetworkDataDto } from "./network-data.model";

export class PrimaryHubDataDto extends NetworkDataDto {

  firewallIP: string = '';
  tenantName: string = '';

  constructor(partial?: Partial<PrimaryHubDataDto>) {
    super();
    Object.assign(this, partial);
  }
}
