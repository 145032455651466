
<h5> Spoke Details </h5>
@if (spokeMetadataForm || (spokeMetadataForm && !formBlocked)) {
  <form [formGroup]="spokeMetadataForm" class="mt-4">
    <div>
      <div class="field">
        <label for="projectId" class="block text-900 font-medium mb-2">Project Id</label>
        <p-dropdown id="projectId" formControlName="projectId" [options]="(projectOptions | async) ?? []" placeholder="Select a Project Profile" appendTo="body" styleClass="w-full mb-3" inputStyleClass="w-full"/>
        <div *ngIf="spokeMetadataForm.controls['projectId'].dirty && !spokeMetadataForm.controls['projectId'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(spokeMetadataForm, 'projectId')">{{ error }}</small>
        </div>
      </div>
    </div>
  </form>
}
@else {
  <div class="flex justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
}

<h5> Spoke Data </h5>
@if (spokeDataExcerptForm || (spokeDataExcerptForm && !formBlocked)) {
  <form [formGroup]="spokeDataExcerptForm" class="mt-4">
    <div>
      <div class="field">
        <label for="subscriptionId" class="block text-900 font-medium mb-2">Azure Subscription Id</label>
        <input id="subscriptionId" formControlName="subscriptionId" type="text" pInputText placeholder="00000000-0000-0000-0000-000000000000" class="w-full">
        <div *ngIf="spokeDataExcerptForm.controls['subscriptionId'].dirty && !spokeDataExcerptForm.controls['subscriptionId'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(spokeDataExcerptForm, 'subscriptionId')">{{ error }}</small>
        </div>
      </div>
      <div class="field">
        <label for="namingConvention" class="block text-900 font-medium mb-2">
          Spoke Name (optional)
          <i class="pi pi-question-circle"
            pTooltip="This field is used for naming the Spoke's Resource Group. It's optional but recommended for easier identification of the Spoke's scope. When choosing your custom name, only alpha-characters can be used, (no numbers, dashes, underscores, or any other illegal characters)">
          </i>
        </label>
        <input id="namingConvention" (change)="onNamingConventionChange()" formControlName="namingConvention" type="text" pInputText class="w-full">
        <div *ngIf="spokeDataExcerptForm.controls['namingConvention'].dirty && !spokeDataExcerptForm.controls['namingConvention'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(spokeDataExcerptForm, 'namingConvention')">{{ error }}</small>
        </div>
      </div>
      <div class="field">
        <label for="topology" class="block text-900 font-medium mb-2">
          Do you require connectivity to the VW corporate backbone (On-Premise)?
        </label>
        <p-dropdown  id="topology" formControlName="topology" [options]="spokeTopologyOptions" optionLabel="label" optionValue="value" placeholder="Please choose the answer" appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
        <div *ngIf="spokeDataExcerptForm.controls['topology'].dirty && !spokeDataExcerptForm.controls['topology'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(spokeDataExcerptForm, 'topology')">{{ error }}</small>
        </div>
      </div>
      <div class="field">
        <label for="location" class="block text-900 font-medium mb-2">Azure Deployment Location</label>
        <p-dropdown id="location" formControlName="location" [options]="(azureRegionOptions | async) ?? []" class="w-full" placeholder="Select target Region" appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
        <div *ngIf="spokeDataExcerptForm.controls['location'].dirty && !spokeDataExcerptForm.controls['location'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(spokeDataExcerptForm, 'location')">{{ error }}</small>
        </div>
      </div>
      <div class="field">
        <label for="environment" class="block text-900 font-medium mb-2">Environment</label>
        <p-dropdown id="environment" formControlName="environment" [options]="(deploymentStageOptions | async) ?? []" placeholder="Select target Environment" appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
        <div *ngIf="spokeDataExcerptForm.controls['environment'].dirty && !spokeDataExcerptForm.controls['environment'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(spokeDataExcerptForm, 'environment')">{{ error }}</small>
        </div>
      </div>
    </div>
  </form>
}
@else {
  <div class="flex justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
}

@if (!formBlocked) {
  <div class="text-center mt-5">
    <p-button label="Cancel" icon="pi pi-times" class="m-3" (click)="onCancelClick()"></p-button>
    <p-button label="Save" icon="pi pi-check" class="m-3" [disabled]="!(spokeMetadataForm?.valid) || !(spokeDataExcerptForm?.valid)" (onClick)="onSaveClick()"></p-button>
  </div>
}
@else {
  <div class="flex-row mt-5">
    <p class="flex-1">Processing...</p>
    <p-progressBar class="flex-1" mode="indeterminate" [style]="{ height: '6px' }" inputStyleClass="w-full" />
  </div>
}
