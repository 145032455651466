import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrimaryHubDto } from '../../api/primary-hub.model';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { formAddValidators } from '../../../../../shared/utils';
import { PrimaryHubService } from '../../api/services/primary-hub.service';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { PrimaryHubDataDto } from '../../api/partials/primary-hub-data.model';
import { ValidationRule } from '../../../../../shared/models/validation-rule';
import { lastValueFrom, map, Observable } from 'rxjs';
import { ConfigurationService } from '../../../shared/api/configuration.service';
import { ProjectProfileService } from '../../../projects/api/project-profile.service';

@Component({
  selector: 'app-project-spokes-primary-hub-create',
  templateUrl: './project-spokes-primary-hub-create.component.html'
})
export class ProjectSpokesPrimaryHubCreateComponent implements OnInit {

  isUpdate: boolean = false;
  formBlocked: boolean = false;
  phMetadataForm?: FormGroup = undefined;
  phDataForm?: FormGroup = undefined;

  phMetadataValidators: ValidationRule[] = [];
  phDataValidators: ValidationRule[] = [];

  deploymentStageOptions?: Observable<string[]>;
  azureRegionOptions?: Observable<string[]>;
  projectOptions?: Observable<string[]>;

  constructor(
    private dialogRef: DynamicDialogRef,
    private formBuilder: FormBuilder,
    private primaryHubService: PrimaryHubService,
    private projectsService: ProjectProfileService,
    private notify: NotificationService,
    private configurationService: ConfigurationService,
  ) {  }

  async ngOnInit() {
    let primaryHub = new PrimaryHubDto();
    let primaryHubData = new PrimaryHubDataDto();

    this.phMetadataValidators = await lastValueFrom(this.primaryHubService.getValidators('OpPrimaryHubCreate'));
    this.phMetadataForm = formAddValidators(this.formBuilder.group(primaryHub), this.phMetadataValidators);

    this.phDataValidators = await lastValueFrom(this.primaryHubService.getValidators('OpPrimaryHubCreate', 'PrimaryHubData'));
    this.phDataForm = formAddValidators(this.formBuilder.group(primaryHubData), this.phDataValidators);

    this.deploymentStageOptions = this.configurationService.deploymentStages();
    this.azureRegionOptions = this.configurationService.azureRegionsForHubs();

    // TODO SnP use 'Lazy Virtual Scroll' and search in dropdown to support large amount of projects
    this.projectOptions = this.projectsService.projectProfiles()
      .pipe(
        map(profiles => profiles.map(profile => profile.projectId))
      );
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSaveClick() {
    this.formBlocked = true;

    if (!this.isUpdate) {
      const ph = <PrimaryHubDto> this.phMetadataForm?.value;
      ph.primaryHubData = this.phDataForm?.value;

      this.primaryHubService.primaryHubCreate(ph)
      .subscribe({
        next: result => {
          if (result) {
            this.notify.success('Saved')
            this.dialogRef.close(result);
          } else {
            this.notify.error('Failed', result);
          }
          this.formBlocked = false;
        },
        error: e => this.formBlocked = false
      });
    }
    else {
      // update is just the second data form
      alert("Update is not implemented yet");
    }
  }

  getErrorsFromControl(form: FormGroup, controlName: string) : string[] {
    const errors = form?.controls[controlName]?.errors;
    return errors ? Object.values(errors) : [];
  }
}
