import { NetworkDataDto } from "./network-data.model";

export class SpokeNetworkDataDto extends NetworkDataDto {

  namingConvention?: string = '';
  topology?: string = '';
  tenantName?: string = '';

  constructor(partial?: Partial<NetworkDataDto>) {
    super(partial);
  }

}
