import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { SpokeNetworkDataDto } from '../../api/partials/spoke-network-data.model';
import { formAddValidators } from '../../../../../shared/utils';
import { SecondaryHubReferenceDto } from '../../api/secondary-hub-reference.model';
import { SpokeReplicaCompleteDto } from '../../api/spoke-replica-complete.model';
import { SpokeReplicaRequestDetailsDto } from '../../api/partials/spoke-replica-request-details.model';
import { SpokeRequestsService } from '../../api/services/spoke-requests.service';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { ValidationRule } from '../../../../../shared/models/validation-rule';
import { lastValueFrom, map, Observable, tap } from 'rxjs';
import { ConfigurationService } from '../../../shared/api/configuration.service';
import { CategoryDropdownOption, SecondaryHubsService } from '../../api/services/secondary-hub.service';
import { ProjectProfileService } from '../../../projects/api/project-profile.service';

@Component({
  selector: 'app-project-spokes-spoke-replicate',
  templateUrl: './project-spokes-spoke-replicate.component.html'
})
export class ProjectSpokesSpokeReplicateComponent implements OnInit{


  formBlocked: boolean = false;
  projectSpokeMetadataForm?: FormGroup;
  secondaryHubReferenceForm?: FormGroup;
  spokeDataForm?: FormGroup;
  spokeRequestForm?: FormGroup;

  projectSpokeMetadataFormValidators: ValidationRule[] = [];
  secondaryHubReferenceValidators: ValidationRule[] = [];
  spokeDataValidators: ValidationRule[] = [];
  spokeRequestValidators: ValidationRule[] = [];

  secondaryHubCategoriesOptions?: Observable<CategoryDropdownOption[]>;
  secondaryHubSubcategoriesOptions?: CategoryDropdownOption[];
  deploymentStageOptions?: Observable<string[]>;
  azureRegionOptions?: Observable<string[]>;
  spokeTopologyOptions: any[] = [];
  projectOptions?: Observable<string[]>;

  secondaryHubSubcategories?: Map<string, CategoryDropdownOption[]>;

  constructor(
    private formBuilder: FormBuilder,
    private spokeRequestsService: SpokeRequestsService,
    private secondaryHubService: SecondaryHubsService,
    private projectsService: ProjectProfileService,
    private notify: NotificationService,
    private router: Router,
    private configurationService: ConfigurationService,
  ){ }

  async ngOnInit() {
    let spokeMetadata = new SpokeReplicaCompleteDto();
    let secondaryHubReference = new SecondaryHubReferenceDto();
    let spokeData = new SpokeNetworkDataDto();
    let spokeRequestDetails = new SpokeReplicaRequestDetailsDto();

    this.projectSpokeMetadataFormValidators = await lastValueFrom(this.spokeRequestsService.getValidators('OpSpokeCreateReplication'));
    this.projectSpokeMetadataForm = formAddValidators(this.formBuilder.group(spokeMetadata), this.projectSpokeMetadataFormValidators);

    this.secondaryHubReferenceValidators = await lastValueFrom(this.spokeRequestsService.getValidators('OpSpokeCreateReplication', 'SecondaryHubReference'));
    this.secondaryHubReferenceForm = formAddValidators(this.formBuilder.group(secondaryHubReference), this.secondaryHubReferenceValidators);

    this.spokeDataValidators = await lastValueFrom(this.spokeRequestsService.getValidators('OpSpokeCreateReplication', 'SpokeData'));
    this.spokeDataForm = formAddValidators(this.formBuilder.group(spokeData), this.spokeDataValidators);
    this.spokeDataForm.controls['namingConvention'].setValue('spoke');

    this.spokeRequestValidators = await lastValueFrom(this.spokeRequestsService.getValidators('OpSpokeCreateReplication', 'RequestDetails'));
    this.spokeRequestForm = formAddValidators(this.formBuilder.group(spokeRequestDetails), this.spokeRequestValidators);

    this.deploymentStageOptions = this.configurationService.deploymentStages();
    this.azureRegionOptions = this.configurationService.azureRegionsForSpokes();
    this.spokeTopologyOptions = this.configurationService.spokeTopologies();

    this.secondaryHubCategoriesOptions = this.secondaryHubService.secondaryHubsCategoriesGet()
      .pipe(
        tap(categories => {
          this.secondaryHubSubcategories = new Map<string, CategoryDropdownOption[]>();
          categories
            .filter(cat => !!cat.categoryId)
            .forEach(cat =>
              this.secondaryHubSubcategories?.set(cat.categoryId!, Array.from(cat.subcategories!).map(subcat => new CategoryDropdownOption(subcat[0], subcat[1])))
          )
        }),
        map(categories => categories.filter(cat => !!cat.categoryId).map(category => new CategoryDropdownOption(category.categoryId!, category.categoryDescription) ))
    );

    // TODO SnP use 'Lazy Virtual Scroll' and search in dropdown to support large amount of projects
    this.projectOptions = this.projectsService.projectProfiles()
      .pipe(
        map(profiles => profiles.map(profile => profile.projectId))
      );
  }

  onCancelClick() {
    this.redirectToProjectSpokes();
  }

  onReturnToProjectSpokesClick() {
    this.redirectToProjectSpokes();
  };

  redirectToProjectSpokes() {
     this.router.navigateByUrl('/nps/project-spokes/list');
  }

  onSaveClick() {
    this.formBlocked = true;

    let spokeReplica = <SpokeReplicaCompleteDto> this.projectSpokeMetadataForm?.value;
    spokeReplica.secondaryHubRef = this.secondaryHubReferenceForm?.value;
    spokeReplica.spokeData = this.spokeDataForm?.value;
    spokeReplica.requestDetails = this.spokeRequestForm?.value;

    if (spokeReplica)
      this.spokeRequestsService.spokeCreateReplication(spokeReplica)
    .subscribe({
      next: result => {
        if (result) {
          this.notify.success('Saved, redirecting back...')
          setTimeout(() => { this.redirectToProjectSpokes() }, 3000);
        } else {
          this.notify.error('Failed', result);
        }
      },
      error: e => this.formBlocked = false
    });
  }

  getErrorsFromControl(form: FormGroup, controlName: string) : string[] {
    const errors = form?.controls[controlName]?.errors;
    return errors ? Object.values(errors) : [];
  }

  onSecondaryHubCategoryValueChange(event: any) {

    if (this.secondaryHubSubcategories
      && event?.value) {

      this.secondaryHubSubcategoriesOptions = this.secondaryHubSubcategories.get(event?.value);
    }
    else {
      this.secondaryHubSubcategoriesOptions = [];
    }
  }

}
