export class SpokeRequestNetworkDataApprovalExcerptDto {

  virtualNetworkCIDR?: string = '';
  subscriptionId?: string = ''; //todo snp make me guid
  namingConvention?: string = '';
  topology?: string = ''; //todo snp make me enum
  environment?: string = ''; //todo snp make me enum
  location?: string = '';

  constructor(partial?: Partial<SpokeRequestNetworkDataApprovalExcerptDto>) {
    Object.assign(this, partial);
  }
}
