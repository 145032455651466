export class SecondaryHubCategoryDetailsDto {

  categoryId: string = '';
  categoryDescription: string = '';

  constructor(partial?: Partial<SecondaryHubCategoryDetailsDto>) {
    Object.assign(this, partial);
  }

}
