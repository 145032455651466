import { SpokeRequestNetworkDataInitExcerptDto } from "./partials/spoke-request-network-data-init-excerpt.model";

export class SpokeRequestInitDto {

  projectId?: string = '';
  spokeData?: SpokeRequestNetworkDataInitExcerptDto;

  constructor(partial?: Partial<SpokeRequestInitDto>) {
    Object.assign(this, partial);
  }
}
