export class NetworkDataDto {

  subscriptionId: string = '';
  resourceGroupName: string = '';
  virtualNetworkName: string = '';
  virtualNetworkCIDR: string = '';
  environment: string = '';
  location: string = '';
  features: string = "{ }";
  tenantId: string = '';

  constructor(partial?: Partial<NetworkDataDto>) {
    Object.assign(this, partial);
  }

}
