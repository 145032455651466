import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PrimaryHubDto } from '../../api/primary-hub.model';

@Component({
  selector: 'app-project-spokes-primary-hub-data',
  templateUrl: './project-spokes-primary-hub-data.component.html',
  styles: ['']
})
export class ProjectSpokesPrimaryHubDataComponent {

  hub!: PrimaryHubDto;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {
    this.hub = this.config.data.hub;
  }

}
