
@if (formReady) {
  <ul>
    <li><span class="font-bold">Secondary Hub Id</span>: {{shub?.secondaryHubId ?? 'ERROR' }}</li>
    <li><span class="font-bold">ProjectId</span>: {{shub?.projectId ?? 'ERROR' }}</li>
    <li><span class="font-bold">Project Name</span>: {{shub?.projectName ?? 'n/a' }}</li>
  </ul>

  <h5>Secondary Hub Category Details</h5>

  <ul>
    <li><span class="font-bold">Category Id</span>: {{shub?.categoryRef?.categoryId ?? 'n/a' }}</li>
    <li><span class="font-bold">Category Description</span>: {{shub?.categoryRef?.categoryDescription ?? 'n/a' }}</li>
  </ul>

  <h5>Secondary Hub Subcategory Details</h5>

  <ul>
    <li><span class="font-bold">Subcategory Id</span>: {{shub?.subcategoryRef?.subcategoryId ?? 'n/a' }}</li>
    <li><span class="font-bold">Subcategory Description</span>: {{shub?.subcategoryRef?.subcategoryDescription ?? 'n/a' }}</li>
  </ul>

  <h5>Secondary Hub Data</h5>

  <ul>
    <li><span class="font-bold">Azure Subscription Id</span>: {{shub?.secondaryHubData?.subscriptionId ?? 'n/a' }}</li>
    <li><span class="font-bold">Azure Tenant Id</span>: {{shub?.secondaryHubData?.tenantId ?? 'n/a' }}</li>
    <li><span class="font-bold">Azure Tenant Name</span>: {{shub?.secondaryHubData?.tenantName ?? 'n/a' }}</li>
    <li><span class="font-bold">Azure Resource Group Name</span>: {{shub?.secondaryHubData?.resourceGroupName ?? 'n/a' }}</li>
    <li><span class="font-bold">Azure Virtual Network Name</span>: {{shub?.secondaryHubData?.virtualNetworkName ?? 'n/a' }}</li>
    <li><span class="font-bold">Azure Virtual Network CIDR</span>: {{shub?.secondaryHubData?.virtualNetworkCIDR ?? 'n/a' }}</li>
    <li><span class="font-bold">Firewall IP</span>: {{shub?.secondaryHubData?.firewallIP ?? 'n/a' }}</li>
    <li><span class="font-bold">Deployment Environment</span>: {{shub?.secondaryHubData?.environment ?? 'n/a' }}</li>
    <li><span class="font-bold">Location</span>: {{shub?.secondaryHubData?.location ?? 'n/a' }}</li>
    <li><span class="font-bold">Private Hub</span>: {{shub?.secondaryHubData?.isPrivate ? 'Yes' : 'No' }}</li>
  </ul>
  <h6>Features</h6>
  <textarea readonly="true" style="width: 80%; overflow: scroll; min-height: 200px;">{{shub?.secondaryHubData?.features ?? '{ }' }}</textarea>
}
@else {
  <div class="flex justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
}
