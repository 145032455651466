import { SecondaryHubCategoryDetailsDto } from "./partials/secondary-hub-category-details.model";
import { SecondaryHubDataDto } from "./partials/secondary-hub-data.model";
import { SecondaryHubSubcategoryDetailsSimpleDto } from "./partials/secondary-hub-subcategory-details-simple.model";

export class SecondaryHubCreateByCategoryDto {

  secondaryHubId?: string = '';

  projectId?: string = '';

  categoryRef?: SecondaryHubCategoryDetailsDto = undefined;

  subcategoryRef?: SecondaryHubSubcategoryDetailsSimpleDto = undefined;

  secondaryHubData?: SecondaryHubDataDto = undefined;

  constructor(partial?: Partial<SecondaryHubCreateByCategoryDto>) {
      Object.assign(this, partial);
  }
}
