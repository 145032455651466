import { SpokeRequestNetworkDataApprovalExcerptDto } from "./partials/spoke-request-network-data-approval-excerpt.model";
import { SecondaryHubReferenceDto } from "./secondary-hub-reference.model";

export class SpokeRequestApprovalDto {

  spokeId?: string = '';
  projectId?: string = '';
  approvalState?: string = ''; //todo snp use enum
  secondaryHubRef?: SecondaryHubReferenceDto;
  spokeData?: SpokeRequestNetworkDataApprovalExcerptDto;

  constructor(partial?: Partial<SpokeRequestApprovalDto>) {
    Object.assign(this, partial);
  }
}
