import { Component, OnInit } from '@angular/core';
import { ProjectSpokeCompleteDto } from '../../api/project-spoke-complete.model';
import { ProjectSpokesService } from '../../api/services/project-spokes.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { tap } from 'rxjs';

@Component({
  selector: 'app-project-spokes-data',
  templateUrl: './project-spokes-data.component.html',
})
export class ProjectSpokesDataComponent implements OnInit {

  projectId: string;
  spokeId: string;

constructor(private config: DynamicDialogConfig) {
  this.projectId = this.config.data.projectId;
  this.spokeId = this.config.data.spokeId;
}

ngOnInit(): void {

}

}
