
<h5>Primary Hub Description</h5>

@if (phMetadataForm || (phMetadataForm && !formBlocked)) {
  <form [formGroup]="phMetadataForm" class="mt-4">
    <div>
      <!-- TODO snp make them visible but readonly -->
      @if(!isUpdate) {
        <div class="field">
          <label for="projectId" class="block text-900 font-medium mb-2">Project Id</label>
          <p-dropdown id="projectId" formControlName="projectId" [options]="(projectOptions | async) ?? []" placeholder="Select a Project Profile" appendTo="body" styleClass="w-full mb-3" inputStyleClass="w-full"/>
          <div *ngIf="phMetadataForm.controls['projectId'].dirty && !phMetadataForm.controls['projectId'].valid" class="block font-sm mb-3 text-red-500" >
            <small *ngFor="let error of getErrorsFromControl(phMetadataForm, 'projectId')">{{ error }}</small>
          </div>
        </div>
        <div class="field">
          <label for="hubId" class="block text-900 font-medium mb-2">PrimaryHub Id</label>
          <input id="hubId" formControlName="hubId" type="text" pInputText placeholder="PH-000" class="w-full">
          <div *ngIf="phMetadataForm.controls['hubId'].dirty && !phMetadataForm.controls['hubId'].valid" class="block font-sm mb-3 text-red-500">
            <small *ngFor="let error of getErrorsFromControl(phMetadataForm, 'hubId')">{{ error }}</small>
          </div>
        </div>
      }

      <div class="field">
        <label for="hubDisplayName" class="block text-900 font-medium mb-2">PrimaryHub Display Name</label>
        <input id="hubDisplayName" formControlName="hubDisplayName" type="text" pInputText placeholder="enter hub name" class="w-full">
        <div *ngIf="phMetadataForm.controls['hubDisplayName'].dirty && !phMetadataForm.controls['hubDisplayName'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(phMetadataForm, 'hubDisplayName')">{{ error }}</small>
        </div>
      </div>
      <div class="field">
        <label for="owner" class="block text-900 font-medium mb-2">Owner</label>
        <input id="owner" formControlName="owner" type="text" pInputText placeholder="enter owner's name or short description" class="w-full">
        <div *ngIf="phMetadataForm.controls['owner'].dirty && !phMetadataForm.controls['owner'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(phMetadataForm, 'owner')">{{ error }}</small>
        </div>
      </div>

    </div>
  </form>
}
@else {
  <div class="flex justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
}

<h5>Primary Hub Data</h5>

@if(phDataForm || (phDataForm && !formBlocked)) {

  <form [formGroup]="phDataForm" class="mt-4">
    <div>

      <!-- TODO snp make them visible but readonly -->
      @if (!isUpdate) {
        <div class="field">
          <label for="tenantId" class="block text-900 font-medium mb-2">TenantId</label>
          <input id="tenantId" formControlName="tenantId" type="text" pInputText placeholder="00000000-0000-0000-0000-000000000000" class="w-full">
          <div *ngIf="phDataForm.controls['tenantId'].dirty && !phDataForm.controls['tenantId'].valid" class="block font-sm mb-3 text-red-500">
            <small *ngFor="let error of getErrorsFromControl(phDataForm, 'tenantId')">{{ error }}</small>
          </div>
        </div>
      }

      <div class="field">
        <label for="firewallIP" class="block text-900 font-medium mb-2">Firewall IP</label>
        <input id="firewallIP" formControlName="firewallIP" type="text" pInputText placeholder="0.0.0.0" class="w-full">
        <div *ngIf="phDataForm.controls['firewallIP'].dirty && !phDataForm.controls['firewallIP'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(phDataForm, 'firewallIP')">{{ error }}</small>
        </div>
      </div>
      <div class="field">
        <label for="subscriptionId" class="block text-900 font-medium mb-2">Azure Subscription ID</label>
        <input id="subscriptionId" formControlName="subscriptionId" type="text" pInputText placeholder="00000000-0000-0000-0000-000000000000" class="w-full">
        <div *ngIf="phDataForm.controls['subscriptionId'].dirty && !phDataForm.controls['subscriptionId'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(phDataForm, 'subscriptionId')">{{ error }}</small>
        </div>
      </div>
      <div class="field">
        <label for="resourceGroupName" class="block text-900 font-medium mb-2">Azure Resource Group Name</label>
        <input id="resourceGroupName" formControlName="resourceGroupName" type="text" pInputText placeholder="eg. rg-azuf-001" class="w-full">
        <div *ngIf="phDataForm.controls['resourceGroupName'].dirty && !phDataForm.controls['resourceGroupName'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(phDataForm, 'resourceGroupName')">{{ error }}</small>
        </div>
      </div>
      <div class="field">
        <label for="virtualNetworkName" class="block text-900 font-medium mb-2">Azure Virtual Network Name</label>
        <input id="virtualNetworkName" formControlName="virtualNetworkName" type="text" pInputText placeholder="eg. vnet-azuf-001" class="w-full">
        <div *ngIf="phDataForm.controls['virtualNetworkName'].dirty && !phDataForm.controls['virtualNetworkName'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(phDataForm, 'virtualNetworkName')">{{ error }}</small>
        </div>
      </div>
      <div class="field">
        <label for="virtualNetworkCIDR" class="block text-900 font-medium mb-2">Azure Virtual Network CIDR</label>
        <input id="virtualNetworkCIDR" formControlName="virtualNetworkCIDR" type="text" pInputText placeholder="0.0.0.0/24" class="w-full">
        <div *ngIf="phDataForm.controls['virtualNetworkCIDR'].dirty && !phDataForm.controls['virtualNetworkCIDR'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(phDataForm, 'virtualNetworkCIDR')">{{ error }}</small>
        </div>
      </div>
      <div class="field">
        <label for="location" class="block text-900 font-medium mb-2">Azure Deployment Location</label>
        <p-dropdown id="location" formControlName="location" [options]="(azureRegionOptions | async) ?? []" class="w-full" placeholder="Select target Region"  appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
        <div *ngIf="phDataForm.controls['location'].dirty && !phDataForm.controls['location'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(phDataForm, 'location')">{{ error }}</small>
        </div>
      </div>
      <div class="field">
        <label for="environment" class="block text-900 font-medium mb-2">Environment</label>
        <p-dropdown id="environment" formControlName="environment" [options]="(deploymentStageOptions | async) ?? []" placeholder="Select target Environment"  appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
        <div *ngIf="phDataForm.controls['environment'].dirty && !phDataForm.controls['environment'].valid" class="block font-sm mb-3 text-red-500">
          <small *ngFor="let error of getErrorsFromControl(phDataForm, 'environment')">{{ error }}</small>
        </div>
      </div>

      <h5>Features</h5>

      <label for="features" class="block text-900 font-medium mb-2">Spoke Features JSON Object</label>
      <textarea
      id="features"
      style="width: 100%; overflow: scroll; min-height: 200px;"
      formControlName="features"
      placeholder="{}">
    </textarea>

  </div>
</form>

}
@else {
  <div class="flex justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
}

@if (!formBlocked) {
  <div class="text-center mt-5">
    <p-button label="Cancel" icon="pi pi-times" class="m-3" (click)="onCancelClick()"></p-button>
    <p-button label="Save" icon="pi pi-check" class="m-3" [disabled]="!(phMetadataForm?.valid) || !(phDataForm?.valid)" (onClick)="onSaveClick()"></p-button>
  </div>
}
@else {
  <div class="flex-row mt-5">
    <p class="flex-1">Processing...</p>
    <p-progressBar class="flex-1" mode="indeterminate" [style]="{ height: '6px' }" inputStyleClass="w-full" />
  </div>
}

