
<ul>
  <li><span class="font-bold">Hub Id</span>: {{hub.hubId ?? 'ERROR' }}</li>
  <li><span class="font-bold">Hub Display Name</span>: {{hub.hubDisplayName ?? 'n/a' }}</li>
  <li><span class="font-bold">Owner</span>: {{hub.owner ?? 'n/a' }}</li>
  <li><span class="font-bold">Project Id</span>: {{hub.projectId ?? 'ERROR' }}</li>
  <li><span class="font-bold">Project Name</span>: {{hub.projectName ?? 'n/a' }}</li>
</ul>

<h5>Primary Hub Data</h5>

<ul>
  <li><span class="font-bold">Azure Subscription Id</span>: {{hub.primaryHubData?.subscriptionId ?? 'n/a' }}</li>
  <li><span class="font-bold">Azure Tenant Id</span>: {{hub.primaryHubData?.tenantId ?? 'n/a' }}</li>
  <li><span class="font-bold">Azure Tenant Name</span>: {{hub.primaryHubData?.tenantName ?? 'n/a' }}</li>
  <li><span class="font-bold">Azure Resource Group Name</span>: {{hub.primaryHubData?.resourceGroupName ?? 'n/a' }}</li>
  <li><span class="font-bold">Azure Virtual Network Name</span>: {{hub.primaryHubData?.virtualNetworkName ?? 'n/a' }}</li>
  <li><span class="font-bold">Azure Virtual Network CIDR</span>: {{hub.primaryHubData?.virtualNetworkCIDR ?? 'n/a' }}</li>
  <li><span class="font-bold">Firewall IP</span>: {{hub.primaryHubData?.firewallIP ?? 'n/a' }}</li>
  <li><span class="font-bold">Deployment Environment</span>: {{hub.primaryHubData?.environment ?? 'n/a' }}</li>
  <li><span class="font-bold">Location</span>: {{hub.primaryHubData?.location ?? 'n/a' }}</li>
</ul>

<h6>Features</h6>
<textarea readonly="true" style="width: 80%; overflow: scroll; min-height: 200px;">{{hub.primaryHubData?.features ?? ' { }' }}</textarea>
