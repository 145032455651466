<div class="flex mt-6 justify-content-center">
  <p-card header="Project Spoke - Replicate existing" subheader="This form allows to replicate existing spoke to enter it into NPS CMDB. All information need to be filled and no external system is called to create it physically." class="w-5">
    <ng-template pTemplate="header">
      <div class="block text-white">
        <i class="bg-primary text-lg pi pi-chevron-left" style="padding:5px"></i>
        <a style="cursor: pointer" (click)="onReturnToProjectSpokesClick()">&nbsp; Return back to Project Spokes</a>
      </div>
    </ng-template>

    <h5>Spoke Description</h5>

    @if (projectSpokeMetadataForm || (projectSpokeMetadataForm && !formBlocked)) {
      <form [formGroup]="projectSpokeMetadataForm" class="mt-4">
        <div>
          <div class="field">
            <label for="spokeId" class="block text-900 font-medium mb-2">Spoke Id</label>
            <input id="spokeId" formControlName="spokeId" type="text" pInputText placeholder="000" class="w-full">
            <div *ngIf="projectSpokeMetadataForm.controls['spokeId'].dirty && !projectSpokeMetadataForm.controls['spokeId'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(projectSpokeMetadataForm, 'spokeId')">{{ error }}</small>
            </div>
          </div>
          <div class="field">
            <label for="projectId" class="block text-900 font-medium mb-2">Project Id</label>
            <p-dropdown id="projectId" formControlName="projectId" [options]="(projectOptions | async) ?? []" placeholder="Select a Project Profile" appendTo="body" styleClass="w-full mb-3" inputStyleClass="w-full"/>
            <div *ngIf="projectSpokeMetadataForm.controls['projectId'].dirty && !projectSpokeMetadataForm.controls['projectId'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(projectSpokeMetadataForm, 'projectId')">{{ error }}</small>
            </div>
          </div>
        </div>
      </form>
    }
    @else {
      <div class="flex justify-content-center">
        <p-progressSpinner></p-progressSpinner>
      </div>
    }

    <h5>Secondary Hub Reference</h5>
    <span>Target Secondary Hub the Spoke is assigned to</span>

    @if (secondaryHubReferenceForm || (secondaryHubReferenceForm && !formBlocked)) {
      <form [formGroup]="secondaryHubReferenceForm" class="mt-4">
        <div>
          <div class="field">
            <label for="categoryId" class="block text-900 font-medium mb-2">Secondary Hub Category Id</label>
            <p-dropdown
              id="categoryId"
              formControlName="categoryId"
              [options]="(secondaryHubCategoriesOptions | async) ?? []"
              optionLabel="name"
              optionValue="value"
              (onChange)="onSecondaryHubCategoryValueChange($event)"
              placeholder="Select SH Category Id"
              appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
          </div>
          <div class="field">
            <label for="subcategoryId" class="block text-900 font-medium mb-2">Secondary Hub Subcategory Id</label>
            <p-dropdown
              id="subcategoryId"
              formControlName="subcategoryId"
              [options]="secondaryHubSubcategoriesOptions ?? []"
              optionLabel="name"
              optionValue="value"
              placeholder="Select SH Category Id first then select Subcategory from this list"
              appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
          </div>
        </div>
      </form>
    }
    @else {
      <div class="flex justify-content-center">
        <p-progressSpinner></p-progressSpinner>
      </div>
    }

    <h5>Spoke Data</h5>

    @if (spokeDataForm || (spokeDataForm && !formBlocked)) {
      <form [formGroup]="spokeDataForm" class="mt-4">
        <div>
          <div class="field">
            <label for="namingConvention" class="block text-900 font-medium mb-2">
              Spoke Name
              <i class="pi pi-question-circle"
                pTooltip="The value used for the resource group name generation by the pipeline, by default it's 'spoke' resulting in rg name like: 'rg-azuf-euw-p-spoke-0032'">
            </i>
            </label>
            <input id="namingConvention" formControlName="namingConvention" type="text" pInputText class="w-full">
            <div *ngIf="spokeDataForm.controls['namingConvention'].dirty && !spokeDataForm.controls['namingConvention'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'namingConvention')">{{ error }}</small>
            </div>
          </div>
          <div class="field">
            <label for="topology" class="block text-900 font-medium mb-2">Do you require connectivity to the VW corporate backbone (On-Premise)?</label>
            <p-dropdown  id="topology" formControlName="topology" [options]="spokeTopologyOptions" optionLabel="label" optionValue="value" placeholder="Please choose the answer" appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
            <div *ngIf="spokeDataForm.controls['topology'].dirty && !spokeDataForm.controls['topology'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'topology')">{{ error }}</small>
            </div>
          </div>
          <div class="field">
            <label for="subscriptionId" class="block text-900 font-medium mb-2">Azure Subscription ID</label>
            <input id="subscriptionId" formControlName="subscriptionId" type="text" pInputText placeholder="00000000-0000-0000-0000-000000000000" class="w-full">
            <div *ngIf="spokeDataForm.controls['subscriptionId'].dirty && !spokeDataForm.controls['subscriptionId'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'subscriptionId')">{{ error }}</small>
            </div>
          </div>
          <div class="field">
            <label for="resourceGroupName" class="block text-900 font-medium mb-2">Azure Resource Group Name</label>
            <input id="resourceGroupName" formControlName="resourceGroupName" type="text" pInputText placeholder="eg. rg-azuf-001" class="w-full">
            <div *ngIf="spokeDataForm.controls['resourceGroupName'].dirty && !spokeDataForm.controls['resourceGroupName'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'resourceGroupName')">{{ error }}</small>
            </div>
          </div>
          <div class="field">
            <label for="virtualNetworkName" class="block text-900 font-medium mb-2">Azure Virtual Network Name</label>
            <input id="virtualNetworkName" formControlName="virtualNetworkName" type="text" pInputText placeholder="eg. vnet-azuf-001" class="w-full">
            <div *ngIf="spokeDataForm.controls['virtualNetworkName'].dirty && !spokeDataForm.controls['virtualNetworkName'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'virtualNetworkName')">{{ error }}</small>
            </div>
          </div>
          <div class="field">
            <label for="virtualNetworkCIDR" class="block text-900 font-medium mb-2">Azure Virtual Network CIDR</label>
            <input id="virtualNetworkCIDR" formControlName="virtualNetworkCIDR" type="text" pInputText placeholder="0.0.0.0/24" class="w-full">
            <div *ngIf="spokeDataForm.controls['virtualNetworkCIDR'].dirty && !spokeDataForm.controls['virtualNetworkCIDR'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'virtualNetworkCIDR')">{{ error }}</small>
            </div>
          </div>
          <div class="field">
            <label for="location" class="block text-900 font-medium mb-2">Azure Deployment Location</label>
            <p-dropdown id="location" formControlName="location" [options]="(azureRegionOptions | async) ?? []" class="w-full" placeholder="Select target Region" appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
            <div *ngIf="spokeDataForm.controls['location'].dirty && !spokeDataForm.controls['location'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'location')">{{ error }}</small>
            </div>
          </div>
          <div class="field">
            <label for="environment" class="block text-900 font-medium mb-2">Environment</label>
            <p-dropdown id="environment" formControlName="environment" [options]="(deploymentStageOptions | async) ?? []" placeholder="Select target Environment" appendTo="body" styleClass="w-full" inputStyleClass="w-full"/>
            <div *ngIf="spokeDataForm.controls['environment'].dirty && !spokeDataForm.controls['environment'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'environment')">{{ error }}</small>
            </div>
          </div>
          <div class="field">
            <label for="tenantId" class="block text-900 font-medium mb-2">Tenant ID</label>
            <input id="tenantId" formControlName="tenantId" type="text" pInputText placeholder="00000000-0000-0000-0000-000000000000" class="w-full">
            <div *ngIf="spokeDataForm.controls['tenantId'].dirty && !spokeDataForm.controls['tenantId'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(spokeDataForm, 'tenantId')">{{ error }}</small>
            </div>
          </div>
          <div class="field">
            <label for="features" class="block text-900 font-medium mb-2">Features</label>
            <textarea id="features" formControlName="features" style="width: 100%; overflow: scroll-y; min-height: 200px;" placeholder="{}"></textarea>
          </div>
        </div>
      </form>
    }
    @else {
      <div class="flex justify-content-center">
        <p-progressSpinner></p-progressSpinner>
      </div>
    }

    <h5>Spoke Request Details</h5>
    <p></p>

    @if (spokeRequestForm || (spokeRequestForm && !formBlocked)) {
      <form [formGroup]="spokeRequestForm" class="mt-4">
        <div>
          <div class="field">
            <label for="createdBy" class="block text-900 font-medium mb-2">Created By</label>
            <input id="createdBy" formControlName="createdBy" type="text" pInputText placeholder="Owner GUID or email" class="w-full">
            <div *ngIf="spokeRequestForm.controls['createdBy'].dirty && !spokeRequestForm.controls['createdBy'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(spokeRequestForm, 'createdBy')">{{ error }}</small>
            </div>
          </div>

          <div class="field">
            <label for="deploymentId" class="block text-900 font-medium mb-2">Deployment Id</label>
            <input id="deploymentId" formControlName="deploymentId" type="text" pInputText placeholder="1234" class="w-full">
            <div *ngIf="spokeRequestForm.controls['deploymentId'].dirty && !spokeRequestForm.controls['deploymentId'].valid" class="block font-sm mb-3 text-red-500">
              <small *ngFor="let error of getErrorsFromControl(spokeRequestForm, 'deploymentId')">{{ error }}</small>
            </div>
          </div>
        </div>
      </form>
    }
    @else {
      <div class="flex justify-content-center">
        <p-progressSpinner></p-progressSpinner>
      </div>
    }

    @if (!formBlocked) {
      <div class="flex gap-3 pt-5">
        <p-button label="Cancel" (click)="onReturnToProjectSpokesClick()" [outlined]="true" class="block w-full mb-4" styleClass="w-full " />
        <p-button label="Save" (click)="onSaveClick()" [outlined]="true" [disabled]="!(projectSpokeMetadataForm?.valid && secondaryHubReferenceForm?.valid && spokeDataForm?.valid && spokeRequestForm?.valid)" class="block w-full mb-4" styleClass="w-full" />
      </div>
    }
    @else {
      <div class="flex-row mt-5">
        <p class="flex-1">Processing...</p>
        <p-progressBar class="flex-1" mode="indeterminate" [style]="{ height: '6px' }" inputStyleClass="w-full" />
      </div>
    }

    <ng-template pTemplate="footer"></ng-template>
  </p-card>

</div>
