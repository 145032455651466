@if (formReady) {
  <div>
    <ul>
      <li><span class="font-bold">Spoke Id</span>: {{projectSpoke?.spokeId ?? 'ERROR' }}</li>
      <li><span class="font-bold">Project Id</span>: {{projectSpoke?.projectId ?? 'ERROR' }}</li>
      <li><span class="font-bold">Project Name</span>: {{projectSpoke?.projectName ?? 'n/a' }}</li>
    </ul>

    <h5>Spoke Data</h5>

    <ul>
      <li><span class="font-bold">Azure Tenant Id</span>: {{projectSpoke?.spokeData?.tenantId ?? 'n/a' }}</li>
      <li><span class="font-bold">Azure Tenant Name</span>: {{projectSpoke?.spokeData?.tenantName  ?? 'n/a' }} </li>
      <li><span class="font-bold">Azure Subscription Id</span>: {{projectSpoke?.spokeData?.subscriptionId ?? 'n/a' }}</li>
      <li><span class="font-bold">Azure Resource Group Name</span>: {{projectSpoke?.spokeData?.resourceGroupName ?? 'n/a' }}</li>
      <li><span class="font-bold">Azure Virtual Network Name</span>: {{projectSpoke?.spokeData?.virtualNetworkName ?? 'n/a' }}</li>
      <li><span class="font-bold">Azure Virtual Network CIDR</span>: {{projectSpoke?.spokeData?.virtualNetworkCIDR ?? 'n/a' }}</li>
      <li><span class="font-bold">Location</span>: {{projectSpoke?.spokeData?.location ?? 'n/a' }}</li>
      <li><span class="font-bold">Topology</span>: {{projectSpoke?.spokeData?.topology ?? 'n/a' }}</li>
      <li><span class="font-bold">Deployment Environment</span>: {{projectSpoke?.spokeData?.environment ?? 'n/a' }}</li>
    </ul>

    <h5>Excerpt from Spoke Request details</h5>

    <ul>
      <li><span class="font-bold">Created By</span>: {{projectSpoke?.requestDetails?.createdBy ?? 'n/a' }}</li>
      <li><span class="font-bold">Approved By</span>: {{projectSpoke?.requestDetails?.approvedBy ?? 'n/a' }}</li>
      <li><span class="font-bold">Creation Time</span>: {{projectSpoke?.requestDetails?.creationDateTime | date : "YYYY-MM-dd hh:mm" }}</li>
      <li><span class="font-bold">Request Current Status</span>: {{projectSpoke?.requestDetails?.status ?? 'n/a' }}</li>
    </ul>
  </div>

  <h6>Features</h6>
  <textarea readonly="true" style="width: 80%; overflow: scroll; min-height: 200px;">{{projectSpoke?.spokeData?.features ?? '{ }' }}</textarea>
}
@else {
  <div class="flex justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
}
