import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { SecondaryHubCompleteDto } from '../../api/secondary-hub-complete.model';
import { SecondaryHubCategoryDetailsDto } from '../../api/partials/secondary-hub-category-details.model';
import { formAddValidators, getObjectKeys } from '../../../../../shared/utils';
import { SecondaryHubDataDto } from '../../api/partials/secondary-hub-data.model';
import { CategoryDropdownOption, SecondaryHubsService } from '../../api/services/secondary-hub.service';
import { SecondaryHubSubcategoryDetailsSimpleDto } from '../../api/partials/secondary-hub-subcategory-details-simple.model';
import { lastValueFrom, map, Observable } from 'rxjs';
import { SecondaryHubCreateByCategoryDto } from '../../api/secondary-hub-create-by-category.model';
import { SecondaryHubCreateBySubcategoryDto } from '../../api/secondary-hub-create-by-subcategory.model';
import { ValidationRule } from '../../../../../shared/models/validation-rule';
import { ConfigurationService } from '../../../shared/api/configuration.service';
import { ProjectProfileService } from '../../../projects/api/project-profile.service';

@Component({
  selector: 'app-project-spokes-secondary-hub-create',
  templateUrl: './project-spokes-secondary-hub-create.component.html'
})
export class ProjectSpokesSecondaryHubCreateComponent {

  isUpdate: boolean = false;
  createCategory: boolean = false;
  formBlocked: boolean = false;
  shCategoryForm?: FormGroup = undefined;
  shSubcategoryForm?: FormGroup = undefined;
  shMetadataForm?: FormGroup = undefined;
  shDataForm?: FormGroup = undefined;

  shMetadataValidators: ValidationRule[] = [];
  shDataValidators: ValidationRule[] = [];

  secondaryHubCategoriesOptions?: Observable<CategoryDropdownOption[]>;
  deploymentStageOptions?: Observable<string[]>;
  azureRegionOptions?: Observable<string[]>;
  projectOptions?: Observable<string[]>;

  constructor(
    private dialogRef: DynamicDialogRef,
    private formBuilder: FormBuilder,
    private secondaryHubService: SecondaryHubsService,
    private projectsService: ProjectProfileService,
    private notify: NotificationService,
    private configurationService: ConfigurationService,
  ) {}

  async ngOnInit() {

    let secondaryHub = this.createCategory ? new SecondaryHubCreateByCategoryDto() : new SecondaryHubCreateBySubcategoryDto();
    let secondaryHubData = new SecondaryHubDataDto();
    let categoryDetails = new SecondaryHubCategoryDetailsDto();
    let subcategoryDetails = new SecondaryHubSubcategoryDetailsSimpleDto();

    this.shMetadataValidators = await lastValueFrom(this.secondaryHubService.getValidators('OpSecondaryHubCreateByCategory'));
    this.shMetadataForm = formAddValidators(this.formBuilder.group(secondaryHub), this.shMetadataValidators);
    this.shMetadataForm.addControl("secondaryHubId", new FormControl());

    this.shCategoryForm = formAddValidators(this.formBuilder.group(categoryDetails), []); // TODO snp add validators from confsvc
    this.shCategoryForm.addControl("categoryIdText", new FormControl());

    this.shSubcategoryForm = formAddValidators(this.formBuilder.group(subcategoryDetails), []); // TODO snp add validators from confsvc

    this.shDataValidators = await lastValueFrom(this.secondaryHubService.getValidators('OpSecondaryHubCreateByCategory', 'SecondaryHubData'));
    this.shDataForm = formAddValidators(this.formBuilder.group(secondaryHubData), this.shDataValidators);

    this.deploymentStageOptions = this.configurationService.deploymentStages();
    this.azureRegionOptions = this.configurationService.azureRegionsForHubs();

    this.secondaryHubCategoriesOptions = this.secondaryHubService.secondaryHubsCategoriesGet()
      .pipe(
        map(categories => categories.filter(category => category.categoryId != null).map(category => new CategoryDropdownOption(category.categoryId!, category.categoryDescription)))
    );

    this.projectOptions = this.projectsService.projectProfiles()
      .pipe(
        map(profiles => profiles.map(profile => profile.projectId))
      );
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSaveClick() {
    this.formBlocked = true;
    if (!this.isUpdate) {

      let result$: Observable<SecondaryHubCompleteDto>;

      if (this.createCategory)
        result$ = this.createByCategory()
      else
        result$= this.createBySubcategory();

      result$
      .subscribe({
        next: result => {
          if (result) {
            this.notify.success('Saved')
            this.dialogRef.close(result);
          } else {
            this.notify.error('Failed', result);
          }
          this.formBlocked = false;
          this.secondaryHubService.secondaryHubsCategoriesInvalidateCache();
        },
        error: e => this.formBlocked = false
      });
    }
    else {
      // update is just the shDataForm
      alert("Update is not implemented yet");
    }
  }

  createByCategory() : Observable<SecondaryHubCompleteDto> {
    var sh = new SecondaryHubCreateByCategoryDto();
    Object.assign(sh, this.shMetadataForm?.value);

    sh.categoryRef = this.shCategoryForm?.value;
    sh.subcategoryRef = this.shSubcategoryForm?.value;
    sh.secondaryHubData = this.shDataForm?.value;

    return this.secondaryHubService.secondaryHubCreateByCategory(sh)
  }

  createBySubcategory() : Observable<SecondaryHubCompleteDto> {
    var sh = new SecondaryHubCreateBySubcategoryDto();
    Object.assign(sh, this.shMetadataForm?.value);

    sh.subcategoryRef = this.shSubcategoryForm?.value;
    sh.secondaryHubData = this.shDataForm?.value;

    const parentCategoryId = this.shCategoryForm?.controls["categoryIdText"]?.value; // todo snp fixme and  check if valid first

    return this.secondaryHubService.secondaryHubCreateBySubcategory(parentCategoryId, sh)
  }

  getErrorsFromControl(form: FormGroup, controlName: string) : string[] {
    const errors = form?.controls[controlName]?.errors;
    return errors ? Object.values(errors) : [];
  }
}
